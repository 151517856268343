<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('hall.default[2]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <van-swipe :autoplay="2000" :vertical="isVertical" v-if="robotList.length>0"
               indicator-color="#191C23"
               class="hallswipe">
      <van-swipe-item v-for="(item, index) in robotList" :key="index">
        <img class="robotheader" :src="`./static/head/${item.header}`" />
        <div style="margin-top: -28px;margin-left: 40px">
          {{item.username}}
          {{$t('hall.default[9]')}} {{item.amount}}USDT {{item.time}}{{$t('common5[1]')}}{{$t('common5[2]')}}
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="infoBox">

<!--      <div class="panelHeader">-->
<!--        {{$t('hall4[2]')}}-->
<!--      </div>-->
      <div style="display: flex">
        <div style="font-size:15px;width: 50%">{{ UserInfo.username.substring(0,15) }}</div>
        <div>{{$t('bindAccount.fields[0]')}} :{{CardInfo.name}}</div>
      </div>
      <div style="display: flex">
        <div style="font-size:15px;width: 50%"> USDT{{ $t("hall.default[1]") }}:{{ parseFloat(UserInfo.balance).toFixed(2)}}</div>
        <div>{{$t('buy.label[2]')}} :{{CardInfo.card_no}}</div>
      </div>
    </div>
    <div class="ScrollBox">
      <div class="infoBox">
        <van-cell-group>
          <van-field readonly
                     v-model="parseFloat(CoinInfo.price).toFixed(2)"
                     :label="$t('buy.label[3]')"
                     :placeholder="$t('bindAccount.placeholder[3]')"
          />
          <van-field readonly
                     v-model="parseFloat(CoinInfo.bestPrice).toFixed(2)"
                     @click="showUsdtType = true"
                     :label="$t('buy.label[4]')"
                     :placeholder="$t('bindAccount.placeholder[4]')"
          />
          <div style="display: flex;background-color: #191C23">
            <van-field required
                       class="input" :border="false"
                       v-model="BankInfo.price"
                       :label="$t('buy.label[5]')"
                       :placeholder="$t('buy.placeholder[0]')"
            />
            <van-button  color="#F0B80D" size="min" style="width: 140px" @click="setPrice">
              <span style="color:#FFF;font-size: 14px">{{$t('buy.label[7]')}}</span>
            </van-button>
          </div>
          <div style="display: flex">
            <van-field required
                       class="input" :border="false"
                       v-model="BankInfo.total_number"
                       :label="$t('buy.label[6]')"
                       :placeholder="$t('buy.placeholder[1]')"
            />
          </div>
          <div style="display: flex">
            <van-field required readonly
                       class="input" :border="false"
                       v-model="counttimestr"
                       @click="showPicker = true"
                       :label="$t('buy3[0]')"
                       :placeholder="$t('buy.placeholder[1]')"
            />
          </div>
          <van-popup v-model:show="showPicker" round position="bottom">
            <van-picker
                    :columns="columns"
                    @cancel="showPicker = false"
                    @change="onChangeCount"
            />
          </van-popup>
          <div style="display: flex">
            <van-field required readonly
                       class="input" :border="false"
                       v-model="ordertypestr"
                       @click="showPicker2 = true"
                       :label="$t('buy3[2]')"
                       :placeholder="$t('buy.placeholder[1]')"
            />
          </div>
          <van-popup v-model:show="showPicker2" round position="bottom">
            <van-picker
                    :columns="columntypes"
                    @cancel="showPicker2 = false"
                    @change="onChangeType"
            />
          </van-popup>
          <div style="display: flex" v-if="BankInfo.ordertype==0">
            <van-field
                    class="input" :border="false"
                    v-model="BankInfo.target_uid"
                    :label="$t('buy3[7]')"
                    :placeholder="$t('buy3[8]')"
            />
          </div>
          <div style="display: flex" v-if="BankInfo.ordertype==1">
            <van-field
                    class="input" :border="false"
                    v-model="BankInfo.count_limit"
                    :label="$t('buy3[5]')"
                    :placeholder="$t('buy3[6]')"
            />
          </div>
          <div style="display: flex;background-color: #252D30">
            <van-field required
                       v-model="BankInfo.min_number"
                       :label="$t('buy.label[10]')"
                       :placeholder="$t('buy.placeholder[2]')"
            />
            <van-field required border
                       v-model="BankInfo.max_number"
                       :placeholder="$t('buy.placeholder[3]')"
            />
          </div>
        </van-cell-group>
      </div>
      <van-button style="margin-top: 50px;margin-left: 40px;width: 75%"
                  size="large"
                  color="#F0B80D"
                  round
                  native-type="button"
                  @click="onSubmit"
      >{{ $t('buy.label[9]') }}</van-button>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'BindAccount',
    components: {

    },
    props: [],
    data() {
      return {
        tabsActive: 0,
        weixinAcc: '',
        showUsdtType: false,
        showPicker:false,
        showPicker2:false,
        CardInfo:{},
        isVertical:true,
        robotList:[],
        counttimes:[2,6,12,24,72,500,2000],
        counttimestr:'',
        ordertypestr:'',
        BankInfo:{price:null,total_number:null,min_number:null,max_number:null,counttime:2,ordertype:0,count_limit:null,target_uid:null},
        CoinInfo:{price:0,bestPrice:0},
        columns : [],
        columntypes:[],
        douyinImg: [],
        kuaishouImg: [],
        accountArr: [],
        tabsList: [],
      }
    },
    computed: {
    },
    watch: {

    },
    created() {
      this.$Model.GetUserInfo()
      this.$Model.GetBankCardList(data=>{
        this.CardInfo = data.data[0];
      });

      this.counttimes.forEach(tt=>{
        var ctime = tt+" "+this.$t("buy3[1]");
        this.columns.push(ctime);
      });
      this.counttimestr = this.columns[0];
      this.BankInfo.counttime = this.counttimes[0];

      this.ordertypestr = this.$t("buy3[3]");
      this.columntypes.push(this.$t("buy3[3]"))
      this.columntypes.push(this.$t("buy3[4]"))

      let usertype = this.UserInfo.user_type;
      if (!usertype||usertype==0){
        this.$Dialog.Toast(this.$t("busAuth2[5]"));
        this.$router.push("/hall");
        return;
      }

      this.usdtinfo = this.InitData.usdtinfo;
      var pump = this.UserInfo.pump;
      this.CoinInfo['price'] = this.usdtinfo ['price'];
      this.CoinInfo['bestPrice'] = this.usdtinfo ['price']*(1+pump);

      this.$parent.navBarTitle = this.$t('bindAccount.default[0]')

      this.$Model.GetRobotTasks({},(data) => {
        // debugger
        if (data['code']==1){
          this.robotList = data['info']
        }
      });


      // this.tabsList = this.InitData.taskclasslist.filter(item=>item.bind_status==1&&item.state==1)
      // this.accountArr = this.tabsList.flatMap(item=>item.bind_type==2?[this.UserInfo[item.bind_field]?[{url:this.ApiUrl+this.UserInfo[item.bind_field]}]:[]]:(this.UserInfo[item.bind_field]||''))
    },
    mounted() {

    },
    activated() {

    },
    destroyed() {

    },
    methods: {
      setPrice(){
        this.BankInfo.price = parseFloat(this.CoinInfo.bestPrice).toFixed(2);
      },
      setAmount(){
        this.BankInfo.total_number=this.UserInfo.balance;
      },
      onSubmit() {
        var bb = parseInt(this.BankInfo.min_number)>parseInt(this.BankInfo.max_number);
        if (this.BankInfo.price<=0){
          this.$Dialog.Toast(this.$t('mytask2[9]'));
          return;
        } else if (this.BankInfo.total_number<=0){
          this.$Dialog.Toast(this.$t('mytask2[7]'));
          return;
        } else if (this.BankInfo.min_number<=0||this.BankInfo.max_number<=0||bb){
          this.$Dialog.Toast(this.$t('mytask2[10]'));
          return;
        }
        this.BankInfo.name = this.CardInfo['name'];
        this.BankInfo.card_no = this.CardInfo['card_no'];
        this.BankInfo.task_type = 1;
        this.$Model.SubmitTask(
                this.BankInfo,
                (data) => {
                  if (data==-1){
                    this.$Dialog.Toast(this.$t('taskOrder2[3]'));
                    return;
                  }
                  console.log("buy: "+JSON.stringify(data));
                  let msg = '';
                  switch (data.code){
                    case 1:
                      msg = this.$t('common3[0]');
                      break;
                    case -1:
                      msg = this.$t('mytask2[7]');
                      break;
                    case -2:
                      msg = this.$t('mytask2[8]');
                      break;
                    case -3:
                      msg = this.$t('mytask2[9]');
                      break;
                    default:
                      msg = data.code_dec;
                      break;
                  }
                  this.$Dialog.Toast(msg);
                  if (data.code==1){
                    this.$router.push("/hall");
                  }
                }
        );
      },
      onChangeCount(obj,value){
        console.log(value);
        this.counttimestr = value
        this.showPicker = false;
        this.counttimes.forEach(tt=>{
          var ttstr = tt+" "+this.$t("buy3[1]");
          if (ttstr==value){
            this.BankInfo.counttime = tt;
          }
        })
      },
      onChangeType(obj,value){
        console.log(value);
        this.ordertypestr = value;
        this.showPicker2 = false;
        if (value==this.$t("buy3[3]")){
          this.BankInfo.ordertype = 0;
        }else{
          this.BankInfo.ordertype = 1;
        }
      },
    }
  }
</script>
<style scoped>
  .PageBox {
    overflow: auto;
    padding-top: 40px;
    background-color:#121617;

  }

  .PageBox>>>.van-nav-bar{
    background: #252D30;
    /* color:#fff; */
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#fff;
  }

  .van-cell {
    background: #252D30;
  }

  .van-cell.input >>> .van-field__control{
    border-bottom: 1px solid;
  }

  .van-cell>>>.van-cell__title{
    font-size: 14px;
  }
  .van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
    margin: 0;
    background-color: #f7f8fa;
    border-radius: 8px;
  }

  .panelHeader {
    margin-top: 0px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    color: #cccccc;
    width: 40%;
    text-align: center;
    margin-bottom: 10px;
  }
  .van-cell {
    background: #191C23;
  }


  .hallswipe {
    width: 96%;
    margin-left: 6px;
    padding: 15px;
    height: 60px;
    z-index: 50;
    margin-top: 5px;
    margin-bottom: 5px;
    color:#cccccc;
    background-color: #191C23;
  }

  .robotheader{
    font-size: 19px;
    border-radius: 50%;
    background: pink;
    overflow: hidden;
    width: 32px;
    height: 32px;
  }
  .infoBox {
    width: 95%;
    margin-left: 10px;
    background-color: #191C23;
    padding: 10px;
    color: #e7e7e7;
    margin-bottom: 10px;
  }

  .btn {
    width: 80%;
    padding: 10px 30px;
    border-radius: 6px;
    background-color: #252D30;
    color: #fff;
    text-align: center;
    margin: 41px 20px 70px 30px;
  }
</style>
